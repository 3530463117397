"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.afto782MetreaSchema = void 0;
const yup = require("yup");
exports.afto782MetreaSchema = yup.object().shape({
    id: yup.string().notRequired(),
    aircraftRegistration: yup.string().nullable(),
    serialNumber: yup.string().nullable(),
    pilotName: yup.string().nullable(),
    takeOffDateTimeZulu: yup.string().nullable(),
    altitude: yup.string().nullable(),
    flightTime: yup.string().nullable(),
    mach: yup.string().nullable(),
    engineMajorCycles: yup.string().nullable(),
    oat: yup.string().nullable(),
    engineMinorCycles: yup.string().nullable(),
    engineNumberOneNOneRpm: yup.string().nullable(),
    engineNumberOneEGT: yup.string().nullable(),
    engineNumberOneNTwoRpm: yup.string().nullable(),
    engineNumberOneFuelFlow: yup.string().nullable(),
    engineNumberOneOilServiceAfterFlight: yup.string().nullable(),
    engineNumberOneThrottlePicture: yup.string().nullable(),
    engineNumberTwoNOneRpm: yup.string().nullable(),
    engineNumberTwoEGT: yup.string().nullable(),
    engineNumberTwoNTwoRpm: yup.string().nullable(),
    engineNumberTwoFuelFlow: yup.string().nullable(),
    engineNumberTwoOilServiceAfterFlight: yup.string().nullable(),
    engineNumberTwoThrottlePicture: yup.string().nullable(),
    engineNumberThreeNOneRpm: yup.string().nullable(),
    engineNumberThreeEGT: yup.string().nullable(),
    engineNumberThreeNTwoRpm: yup.string().nullable(),
    engineNumberThreeFuelFlow: yup.string().nullable(),
    engineNumberThreeOilServiceAfterFlight: yup.string().nullable(),
    engineNumberThreeThrottlePicture: yup.string().nullable(),
    engineNumberFourNOneRpm: yup.string().nullable(),
    engineNumberFourEGT: yup.string().nullable(),
    engineNumberFourNTwoRpm: yup.string().nullable(),
    engineNumberFourFuelFlow: yup.string().nullable(),
    engineNumberFourOilServiceAfterFlight: yup.string().nullable(),
    engineNumberFourThrottlePicture: yup.string().nullable(),
    remarks: yup.string().nullable(),
    eventId: yup.string().nullable(),
    isLocked: yup.boolean().required(),
});
