"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.inFlightIssueLogMetreaSchema = void 0;
const yup = require("yup");
exports.inFlightIssueLogMetreaSchema = yup.object().shape({
    id: yup.string().notRequired(),
    missionNumber: yup.string().nullable(),
    techLogNumber: yup.string().nullable(),
    eventNumber: yup.string().nullable(),
    plannedActualMissionTimingRows: yup.array().nullable(),
    tailNumber: yup.string().nullable(),
    area: yup.string().nullable(),
    receiverInformationRows: yup.array().nullable(),
    aroName: yup.string().nullable(),
    totalsMprsLh: yup.string().nullable(),
    totalsMprsRh: yup.string().nullable(),
    totalsContactWet: yup.string().nullable(),
    totalsContactDry: yup.string().nullable(),
    totalsOffloadKlbs: yup.string().nullable(),
    commentsOrDescription: yup.string().nullable(),
    eventId: yup.string().nullable(),
    isLocked: yup.boolean().required(),
});
