"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FirebaseCollection = void 0;
var FirebaseCollection;
(function (FirebaseCollection) {
    FirebaseCollection["Users"] = "Users";
    FirebaseCollection["Squadrons"] = "Squadrons";
    FirebaseCollection["Events"] = "Events";
    FirebaseCollection["EventIDs"] = "EventIDs";
    FirebaseCollection["AircrewTypes"] = "AircrewTypes";
    FirebaseCollection["UsersEvents"] = "UsersEvents";
    FirebaseCollection["Url"] = "Url";
    FirebaseCollection["Mds"] = "Mds";
    FirebaseCollection["UsersSupportRequests"] = "UsersSupportRequests";
    FirebaseCollection["Notifications"] = "Notifications";
    FirebaseCollection["UserAvailability"] = "UserAvailability";
    FirebaseCollection["EventComments"] = "EventComments";
    FirebaseCollection["Its"] = "Its";
    FirebaseCollection["AccomplishmentReports"] = "AccomplishmentReports";
    FirebaseCollection["Rating"] = "Rating";
    FirebaseCollection["EventLogger"] = "EventLogger";
    FirebaseCollection["FailedPushNotifications"] = "FailedPushNotifications";
    FirebaseCollection["SarmCodes"] = "SarmCodes";
    FirebaseCollection["Afto781"] = "Afto781";
    FirebaseCollection["Af1522"] = "Af1522";
    FirebaseCollection["Af3956"] = "Af3956";
    FirebaseCollection["Afto781Metrea"] = "Afto781Metrea";
    FirebaseCollection["Afto782Metrea"] = "Afto782Metrea";
    FirebaseCollection["InFlightIssueLogMetrea"] = "InFlightIssueLogMetrea";
})(FirebaseCollection = exports.FirebaseCollection || (exports.FirebaseCollection = {}));
